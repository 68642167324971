<template>
	<div class="space-y-2 w-full">
		<label :class="labelClass" :for="id" v-if="label">{{ label }}</label>
		<div class="flex items-center focus-within:ring focus-within:ring-zinc-400 rounded-xl" :class="wrapperClass">
			<slot name="beforeInput"></slot>

			<input
				v-if="!loading"
				ref="inputRef"
				:id="id"
				:type="type"
				:disabled="disabled"
				:class="computedClass"
				:value="inputModel"
				v-mask="options"
				:placeholder="placeholder"
				@input="e => (inputModel = (e.target as HTMLInputElement).value)"
				v-bind="$attrs"
			/>
			<div v-if="loading" :class="twMerge('flex items-center', computedClass)">
				<LoaderCircle class="animate-spin text-zinc-500 h-4 w-4" />
			</div>
		</div>
		<span v-if="error" class="is-error text-red-400 text-sm mt-1">{{ error }}</span>
	</div>
</template>

<script setup lang="ts">
import { twMerge } from 'tailwind-merge'

import type { MaskInputOptions } from 'maska'
import { LoaderCircle } from 'lucide-vue-next'

const modelValue = defineModel()
const id = useId()
const props = defineProps<{
	mask?: String | Function | RegExp
	loading?: boolean
	moneyMask?: boolean
	label?: string
	disabled?: boolean
	error?: string
	wrapperClass?: string
	type: string
	class?: string
	placeholder?: string
	labelClass?: string
	variant?: 'default'
	tokens?: any
}>()

const inputModel = computed({
	get: () => modelValue.value,
	set: value => {
		modelValue.value = value
	},
})

const options = reactive<MaskInputOptions>(
	props.mask || props.moneyMask
		? {
				mask: (props.mask as any) ?? undefined,
				postProcess: (value: string) => {
					if (props.moneyMask) {
						if (!value) {
							return 'R$ 0,00'
						}

						return formatCurrency(+value)
					}
					return value
				},
				tokens: (props.tokens as any) ?? {},
		  }
		: {}
)

const classes = {
	default: 'rounded-xl h-[52px] w-full bg-[#F3F5F7] outline-none disabled:opacity-60',
}

const computedClass = computed(() => {
	return twMerge(classes.default, props.variant ? [props.variant] : '', props.class)
})
</script>
